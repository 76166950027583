import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import dropin from "braintree-web-drop-in";

const ImageAnalysisForm = () => {
  const [podTitle, setPodTitle] = useState("");
  const [podDescription, setPodDescription] = useState("");
  const [initialLotNumber, setInitialLotNumber] = useState(1);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [clientToken, setClientToken] = useState(null);
  const [paid, setPaid] = useState(false);
  const dropinInstance = useRef(null);
  const userId = localStorage.getItem("userId");

  // Resize images
  const resizeImage = (file, index, totalFiles) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        70,
        0,
        (uri) => {
          const progressPercentage = Math.round(((index + 1) / totalFiles) * 100);
          setProgress(progressPercentage);
          setCurrentFile(file.name);
          resolve(uri);
        },
        "file"
      );
    });
  };

  // Handle image file selection and resize
  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (!selectedFiles.length) return;

    setLoading(true);
    try {
      const resizedFiles = await Promise.all(
        selectedFiles.map((file, index) => resizeImage(file, index, selectedFiles.length))
      );
      setFiles(resizedFiles);
      alert(`Resized ${resizedFiles.length} files.`);
    } catch (error) {
      console.error("Error resizing images:", error);
      alert("Failed to resize images.");
    } finally {
      setLoading(false);
    }
  };

  // Split files into chunks
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const uploadChunk = async (chunk, podId) => {
    const formData = new FormData();
    if (podId) formData.append("podId", podId);
    formData.append("userId", userId);
    formData.append("podTitle", podTitle);
    formData.append("podDescription", podDescription);
    formData.append("initialLotNumber", initialLotNumber);
    chunk.forEach((file) => formData.append("files", file));

    const response = await axios.post(
      "https://devbackend.listornot.com/create-pod",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    return response.data;
  };

  const handleSubmit = async () => {


    if (!podTitle || !files.length || !podDescription) {
      alert("Please provide all required details.");
      return;
    }

    setLoading(true);

    try {
      const fileChunks = chunkArray(files, 10);
      let podId = null;

      for (const [index, chunk] of fileChunks.entries()) {
        setCurrentBatch(`Processing batch ${index + 1} of ${fileChunks.length}`);
        const response = await uploadChunk(chunk, podId);
        if (!podId) {
          podId = response.podId;
        }
      }

      alert("All files uploaded successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files.");
    } finally {
      setLoading(false);
    }
  };

  const initializePayment = async () => {
    try {
      const response = await axios.get("https://devbackend.listornot.com/token"); // Fetch client token
      setClientToken(response.data.clientToken);

      dropin.create(
        {
          authorization: response.data.clientToken,
          container: "#dropin-container",
        },
        (error, instance) => {
          if (error) {
            console.error("Error initializing payment:", error);
            return;
          }
          dropinInstance.current = instance;
        }
      );
    } catch (error) {
      console.error("Error fetching client token:", error);
    }
  };

  const handlePayment = async () => {
    try {
      const { nonce } = await dropinInstance.current.requestPaymentMethod();

      const response = await axios.post("https://devbackend.listornot.com/checkout", {
        paymentMethodNonce: nonce,
        amount: (files.length * 0.3).toFixed(2), // Calculate the amount
      });

      if (response.data.success) {
        alert("Payment Successful!");
        setPaid(true); // Mark payment as completed
        handleSubmit()
      } else {
        alert(`Payment Failed: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Payment error:", error);
      alert("Payment failed. Please try again.");
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      initializePayment();
    }
  }, [files]);

  return (
    <div className="p-4 bg-white rounded shadow-md space-y-4">
      <h2 className="text-xl font-semibold text-gray-700">Image Analysis Request</h2>
      <form  className="space-y-4">
        <div>
          <label className="block font-medium text-gray-700">POD Title</label>
          <input
            type="text"
            value={podTitle}
            onChange={(e) => setPodTitle(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>
        <div>
          <label className="block font-medium text-gray-700">Pod Description</label>
          <textarea
            value={podDescription}
            onChange={(e) => setPodDescription(e.target.value)}
            placeholder="Enter a description for the pod"
            className="mt-1 block w-full border border-gray-300 rounded p-2"
            rows={4}
          />
        </div>
        <div>
          <label className="block font-medium text-gray-700">Initial Lot Number</label>
          <input
            type="number"
            value={initialLotNumber}
            onChange={(e) => setInitialLotNumber(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
        </div>
        <div>
          <label className="block font-medium text-gray-700">Upload Images</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          />
          {loading && (
            <div className="mt-4">
              <p className="text-sm text-gray-500">
                Processing {currentFile}... {progress}% Complete
              </p>
              <progress value={progress} max={100} className="w-full mt-2" />
              <p className="text-sm text-gray-500">{currentBatch}</p>
            </div>
          )}
        </div>

        {files.length > 0 && !paid && (
          <div className="mt-4">
            <h3 className="text-md font-semibold">Total: ${(files.length * 0.3).toFixed(2)}</h3>
            <div id="dropin-container" className="mt-4"></div>
            <button
              type="button"
              onClick={handlePayment}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Pay Now
            </button>
          </div>
        )}

      </form>
    </div>
  );
};

export default ImageAnalysisForm;