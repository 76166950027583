import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./firebase"; 
import LandingPage from "./LandingPage";
import ImageUploader from "./ImageUploader";
import BarcodeLookup from "./BarcodeLookup";
import CSVUploader from "./CSVUploader";
import ImageAnalysisForm from "./ImageAnslysisForm";
import Sidebar from "./Navbar";  // <-- The newly created sidebar
import UserSettings from "./UserSettings";


const drawerWidth = 240;

const App = () => {
  const [user, setUser] = useState(null); 
  const [selectedComponent, setSelectedComponent] = useState("CSVUploader");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <Router>
      <Routes>
        {/* Public Route: Landing Page */}
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard" /> : <LandingPage />}
        />

        {/* Protected Route: Dashboard */}
        <Route
          path="/dashboard"
          element={
            user ? (
              <Dashboard
                selectedComponent={selectedComponent}
                setSelectedComponent={setSelectedComponent}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

const Dashboard = ({ selectedComponent, setSelectedComponent }) => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar setSelectedComponent={setSelectedComponent} />
      {/* Main content area: add padding or margin to account for the sidebar width */}
      <div style={{ marginLeft: "10px", padding: "1rem", flexGrow: 1 }}>
        {selectedComponent === "CSVUploader" && <CSVUploader />}
        {selectedComponent === "ImageUploader" && <ImageUploader />}
        {selectedComponent === "BarcodeLookup" && <BarcodeLookup />}
        {selectedComponent === "ImageAnalysisRequest" && <ImageAnalysisForm />}
        {selectedComponent === "UserSettings" && <UserSettings />}

      </div>
    </div>
  );
};

export default App;