import React, { useState, useEffect } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from "@mui/icons-material/Delete";

const ItemType = {
  IMAGE: "image",
  LOT: "lot",
};

const LotDisplay = ({
  lots,
  initialLotNumber,
  deleteLotRearrange,
  deleteLotKeepStructure,
  moveImageToLot,
  createNewEmptyLot,
}) => {
  const [showLot, setShowLot] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLot(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const Lot = ({ lot, lotIndex }) => {
    const [, dropRef] = useDrop({
      accept: [ItemType.IMAGE],
      drop: (item) => {
        // Move image to this lot when dropped
        moveImageToLot(item.fromLotIndex, lotIndex, item.imageIndex);
      },
    });

    return (
      <div
        ref={dropRef}
        className="border p-4 rounded-md mt-4 bg-gray-100 shadow-lg"
      >
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
          <h3 className="text-md font-semibold">
            Lot {lot.lotIndex || lotIndex + initialLotNumber}
          </h3>
          <div className="flex flex-wrap sm:flex-nowrap items-center gap-2">
            <button onClick={() => deleteLotRearrange(lotIndex)}>
              <DeleteIcon />
              {/* <span className="ml-2">Delete & Rearrange</span> */}
            </button>
            {/* <button onClick={() => deleteLotKeepStructure(lotIndex)}>
              <DeleteIcon />
              <span className="ml-2">Delete Without Rearrange</span>
            </button> */}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          {Array.isArray(lot.imageUrl) &&
            lot.imageUrl.map((original, imgIndex) => (
              <Image
                key={imgIndex}
                original={original}
                fromLotIndex={lotIndex}
                imageIndex={imgIndex}
              />
            ))}
        </div>
      </div>
    );
  };

  const Image = ({ original, fromLotIndex, imageIndex }) => {
    const [, dragRef] = useDrag({
      type: ItemType.IMAGE,
      item: { fromLotIndex, imageIndex },
    });

    return (
      <img
        ref={dragRef}
        src={original}
        alt={`original ${imageIndex}`}
        className="h-auto rounded-md shadow-lg shadow-zinc-500 cursor-pointer"
      />
    );
  };

  const DropZone = ({ position }) => {
    return (
      <div className="w-full flex justify-center items-center my-4">
        <button
          onClick={() => createNewEmptyLot(position)}
          className="flex items-center px-4 py-2 bg-green-500 text-white rounded-full shadow hover:bg-green-600 focus:outline-none"
        >
          <span className="text-lg font-bold">+</span>
          <span className="ml-2">Create New Pod</span>
        </button>
      </div>
    );
  };

  if (!showLot) {
    return (
      <div className="p-4 mt-4 bg-gray-100 rounded-md border">
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", marginBottom: "8px" }}
          width="40%"
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "0.875rem", marginBottom: "16px" }}
          width="60%"
        />
        <Skeleton variant="rectangular" width="100%" height={150} />
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {lots.map((lot, index) => (
        <React.Fragment key={index}>
          <Lot lot={lot} lotIndex={index} />
          {/* Add DropZone between lots */}
          <DropZone position={index + 1} />
        </React.Fragment>
      ))}
    </DndProvider>
  );
};

export default LotDisplay;