import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LotDisplay from "./LotDisplay";
import { saveAs } from "file-saver";

const ImageUploader = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [imageAnalyses, setImageAnalyses] = useState([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [lots, setLots] = useState([]);
  const [analysisStatus, setAnalysisStatus] = useState(null);
  const [initialLotNumber, setInitialLotNumber] = useState(1);
  const [removeBg, setRemoveBg] = useState(false);
  const [podDescription, setPodDescription] = useState("");
  const [polling, setPolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [customTones, setCustomTones] = useState({});
  const [selectedTone, setSelectedTone] = useState("");
  const [transformedLots, setTransformedLots] = useState([]);
  const POLLING_INTERVAL = 3000; // 3 seconds
  const lotsWithEmptyTitles = lots?.filter((lot) => !lot.analysis?.title)
    .map((lot, index) => lot.lotIndex || index + initialLotNumber);


  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://devbackend.listornot.com/get-all-users");
      setUsers(response.data.users || []);
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };
  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://devbackend.listornot.com/get-user-details?userId=${userId}`);
      const { customTone } = response.data;

      setCustomTones(customTone || {});
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };
  const fetchUserAnalyses = async (userId) => {
    try {
      const response = await axios.get(`https://devbackend.listornot.com/get-user-analyses?userId=${userId}`);
      setImageAnalyses(response.data.analyses || []);
    } catch (error) {
      console.error("Error fetching user analyses:", error.message);
    }
  };

  const fetchAnalysisDetails = async (userId, analysisId) => {
    try {
      const response = await axios.get(
        `https://devbackend.listornot.com/get-analysis-lots?userId=${userId}&analysisId=${analysisId}`
      );
      const { lots: fetchedLots, initialLotNumber, podDescription, status } = response.data;

      setLots(fetchedLots || []);
      setInitialLotNumber(initialLotNumber || 1);
      setPodDescription(podDescription || "");
      setAnalysisStatus(status);

      // Stop polling if analysis is completed
      if (status === "completed") {
        setPolling(false);
      }
    } catch (error) {
      console.error("Error fetching analysis details:", error.message);
    }
  };

  const handleSendToLN = async () => {
    try {
      if (!lots.length || !subscriptionId) {
        alert("All fields (Pod ID and Subscription ID) are required.");
        return;
      }

    
      
      const transformedLots = lots.map((processedlot) => ({
        lotIndex: processedlot.lotIndex || 0,
        description: "",
        imageUrl: processedlot.imageUrl || [],
        results: {
          title: processedlot.analysis?.title || "",
          description: processedlot.analysis?.description?.replace(/\*/g, "") || "",
          price: processedlot.analysis?.price || 0,
          category: processedlot.analysis?.category || "",
          weight: processedlot.analysis?.weight || "",
          dimensions: processedlot.analysis?.dimensions
          ? {
              height: processedlot.analysis?.dimensions?.height || "",
              length: processedlot.analysis?.dimensions?.length || "",
              width: processedlot.analysis?.dimensions?.width || "",
            }
          : { height: "", length: "", width: "" },
          quantity:  1,
          mpn: processedlot.analysis?.mpn || "",
          model: processedlot.analysis?.model || "",
          manufacturer: processedlot.analysis?.manufacturer || "",
          brand: processedlot.analysis?.brand || "",
          color: processedlot.analysis?.color || "",
          gender: processedlot.analysis?.gender || "",
          pattern: processedlot.analysis?.pattern || "",
          size: processedlot.analysis?.size || "",
          condition: processedlot.analysis?.condition || "",
          upc: processedlot.analysis?.upc || "",
          asin: processedlot.analysis?.asin || "",
          original: processedlot.original || [],
          noBackground: processedlot.noBackground || [],
          alternative_categories: processedlot.analysis?.alternative_categories || [],
          alternative_prices: processedlot.analysis?.alternative_prices || {},
          specifications: processedlot.analysis?.specifications || {},
          similar_items: processedlot.analysis?.similar_items || [],
        },
      }));
      

      
      const payload = {
        PodName: selectedAnalysis || "default_pod",
        ApiKey: "q0wY5liJaudGSTeF8e94vtBh2f83",
        lalPayload: transformedLots,
        SubscriptionId: subscriptionId,
      };
      
      console.log("Problematic Lot:", JSON.stringify(payload.lalPayload[28], null, 2));
      
   


      const response = await axios.post(`https://listernautapi.azurewebsites.net/api/v2/listalot/import`, payload);
      alert("Lots sent successfully to LN.");
      console.log("Response from LN:", response.data);
      setModalOpen(false);

    } catch (error) {
      console.error("Error sending lots to LN:", error.message);
      if (error.response && error.response.data) {
        console.error("API response data:", error.response.data);
      }
      alert("Failed to send lots to LN.");
    }
  };
  const isValidLot = (lot) => {
    const requiredFields = [
      "analysis",
      "imageUrl",
      "lotIndex",
      "original",
      "noBackground",
    ];
  
    // Check if all required fields exist
    for (const field of requiredFields) {
      if (!lot[field]) {
        console.error(`Missing required field: ${field}`);
        return false;
      }
    }
  
    // Check if analysis has required subfields
    const analysisRequiredFields = [
      "title",
      "description",
      "price",
      "category",
      "quantity",
      "manufacturer",
      "brand",
    ];
  
    for (const field of analysisRequiredFields) {
      if (!(field in lot.analysis)) {
        console.error(`Missing required analysis field: ${field}`);
        return false;
      }
    }
  
    // Validate data types
    if (!Array.isArray(lot.imageUrl) || !Array.isArray(lot.original) || !Array.isArray(lot.noBackground)) {
      console.error("imageUrl, original, and noBackground must be arrays");
      return false;
    }
  
    if (typeof lot.lotIndex !== "number") {
      console.error("lotIndex must be a number");
      return false;
    }
  
    if (typeof lot.analysis.title !== "string") {
      console.error("Analysis title must be a string");
      return false;
    }
  
    if (typeof lot.analysis.description !== "string") {
      console.error("Analysis description must be a string");
      return false;
    }
  
    if (typeof lot.analysis.price !== "number") {
      console.error("Analysis price must be a number");
      return false;
    }
  
    if (typeof lot.analysis.category !== "string") {
      console.error("Analysis category must be a string");
      return false;
    }
  
    if (typeof lot.analysis.quantity !== "number") {
      console.error("Analysis quantity must be a number");
      return false;
    }
  
    if (typeof lot.analysis.manufacturer !== "string" && lot.analysis.manufacturer !== "") {
      console.error("Analysis manufacturer must be a string or an empty string");
      return false;
    }
  
    if (typeof lot.analysis.brand !== "string" && lot.analysis.brand !== "") {
      console.error("Analysis brand must be a string or an empty string");
      return false;
    }
  
    // Additional checks for nested fields
    if (
      !Array.isArray(lot.analysis.alternative_categories) ||
      !Array.isArray(lot.analysis.similar_items)
    ) {
      console.error("alternative_categories and similar_items must be arrays");
      return false;
    }
  
    if (
      typeof lot.analysis.alternative_prices !== "object" ||
      Array.isArray(lot.analysis.alternative_prices)
    ) {
      console.error("alternative_prices must be an object");
      return false;
    }
  
    return true;
  };
  
  const checkDataFormat = (lots) => {
    const invalidLots = [];
    lots.forEach((lot, index) => {
      const isValid = isValidLot(lot);
      if (!isValid) {
        invalidLots.push(index + 1); // Store the lotIndex for easier debugging
      }
    });
  
    if (invalidLots.length > 0) {
      console.error(
        `Invalid lots found at indices: ${invalidLots.join(", ")}. Please correct the data format.`
      );
      return false;
    }
  
    console.log("All lots passed validation.");
    return true;
  };
  const generateCSV = (data) => {
    const flattenObject = (obj, prefix = "") =>
      Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          Object.assign(acc, flattenObject(value, prefixedKey));
        } else {
          acc[prefixedKey] = Array.isArray(value) ? value.join(", ") : value;
        }
        return acc;
      }, {});

    const flattenedData = data.map((item) => flattenObject(item));
    const headers = Object.keys(flattenedData[0]);
    const csvContent = [
      headers.join(","), // Add headers
      ...flattenedData.map((row) =>
        headers.map((header) => `"${row[header] || ""}"`).join(",")
      ), // Add rows
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "transformed_lots.csv");
  };

  const handleDownloadCSV = () => {
    if (transformedLots.length > 0) {
      generateCSV(transformedLots);
    } else {
      alert("No transformed lots available to download.");
    }
  };

  const handleTransformLots = () => {
    const transformed = lots.map((processedlot) => ({
      lotIndex: processedlot.lotIndex || 0,
      description: "",
      imageUrl: processedlot.imageUrl || [],
      results: {
        title: processedlot.analysis?.title || "",
        description: processedlot.analysis?.description?.replace(/\*/g, "") || "",
        price: processedlot.analysis?.price || 0,
        category: processedlot.analysis?.category || "",
        weight: processedlot.analysis?.weight || "",
        dimensions: processedlot.analysis?.dimensions || {
          height: "",
          length: "",
          width: "",
        },
        quantity:  1,
        mpn: processedlot.analysis?.mpn || "",
        model: processedlot.analysis?.model || "",
        manufacturer: processedlot.analysis?.manufacturer || "",
        brand: processedlot.analysis?.brand || "",
        color: processedlot.analysis?.color || "",
        gender: processedlot.analysis?.gender || "",
        pattern: processedlot.analysis?.pattern || "",
        size: processedlot.analysis?.size || "",
        condition: processedlot.analysis?.condition || "",
        upc: processedlot.analysis?.upc || "",
        asin: processedlot.analysis?.asin || "",
        original: processedlot.original || [],
        noBackground: processedlot.noBackground || [],
        alternative_categories: processedlot.analysis?.alternative_categories || [],
        alternative_prices: processedlot.analysis?.alternative_prices || {},
        specifications: processedlot.analysis?.specifications || {},
        similar_items: processedlot.analysis?.similar_items || [],
      },
    }));

    setTransformedLots(transformed);
    alert("Lots transformed successfully!");
  };
  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    setImageAnalyses([]);
    setSelectedAnalysis(null);
    setLots([]);
    setAnalysisStatus(null);
    setPolling(false);

    if (userId) {
      fetchUserAnalyses(userId);
      fetchUserDetails(userId)
    }
  };
  const retryFailedLots = async () => {
    if (!lotsWithEmptyTitles.length) {
      alert("No failed lots to retry.");
      return;
    }

    const payload = {
      podId: selectedAnalysis,
      lots: lotsWithEmptyTitles,
      removeBg,
      podDescription,
      userId: selectedUserId,
      retryFailedLots: true
    };

    try {
      setLoading(true);
      await axios.post("https://devbackend.listornot.com/finalize-analysis", payload);
      alert("Retry analysis started for failed lots. Check back later for results.");
      setPolling(true);
    } catch (error) {
      console.error("Error retrying failed lots:", error.message);
      alert("Failed to retry analysis for failed lots.");
    } finally {
      setLoading(false);
    }
  };
  const handleAnalysisChange = (event) => {
    const analysisId = event.target.value;
    setSelectedAnalysis(analysisId);

    if (analysisId && selectedUserId) {
      fetchAnalysisDetails(selectedUserId, analysisId);
    }
  };

  const updatePod = async () => {
    if (!selectedAnalysis || !lots.length || !podDescription) {
      alert("Please ensure a Pod is selected and contains lots with a description.");
      return;
    }

    const payload = {
      podId: selectedAnalysis,
      lots,
      podDescription,
      initialLotNumber,
      userId: selectedUserId,
    };

    try {
      setLoading(true);
      const response = await axios.post("https://devbackend.listornot.com/update-pod", payload);
      alert("Pod updated successfully!");

    } catch (error) {
      console.error("Error updating pod:", error.message);
      alert("Failed to update pod. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const analyzeImages = async () => {
    if (!selectedAnalysis || !lots.length) {
      alert("Please ensure a Pod is selected and contains lots.");
      return;
    }

    const payload = {
      podId: selectedAnalysis,
      lots,
      removeBg,
      podDescription,
      userId: selectedUserId,
    };

    try {
      setAnalysisStatus("in_progress");
      await axios.post("https://devbackend.listornot.com/finalize-analysis", payload);


      setPolling(true); // Start polling
    } catch (error) {
      console.error("Error in analyzing images:", error.message);
      alert("Failed to start analysis. Please try again.");
    }
  };

  const moveImageToLot = (fromLotIndex, toLotIndex, imageIndex) => {
    const updatedLots = [...lots];

    // Ensure source lot is valid and has an imageUrl array
    if (!updatedLots[fromLotIndex] || !Array.isArray(updatedLots[fromLotIndex].imageUrl)) {
      console.error(`Source lot at index ${fromLotIndex} is invalid or does not have an imageUrl array`);
      return;
    }

    // Extract the image using the imageIndex
    const image = updatedLots[fromLotIndex].imageUrl[imageIndex];
    if (!image) {
      console.error(`Image at index ${imageIndex} in lot ${fromLotIndex} is invalid`);
      return;
    }

    // Ensure destination lot exists and has an imageUrl array
    if (!updatedLots[toLotIndex]) {
      updatedLots[toLotIndex] = { lotIndex: toLotIndex + 1, imageUrl: [] };
    } else if (!Array.isArray(updatedLots[toLotIndex].imageUrl)) {
      updatedLots[toLotIndex].imageUrl = [];
    }

    // Remove the image from the source lot
    updatedLots[fromLotIndex].imageUrl = updatedLots[fromLotIndex].imageUrl.filter(
      (_, idx) => idx !== imageIndex
    );

    // Add the image to the destination lot
    updatedLots[toLotIndex].imageUrl.push(image);

    setLots(updatedLots);
    console.log("Updated lots after moving image:", updatedLots);
  };
  const deleteLotRearrange = (lotIndex) => {
    const updatedLots = [...lots];
    updatedLots.splice(lotIndex, 1);

    // Update the lotIndex for all subsequent lots
    for (let i = lotIndex; i < updatedLots.length; i++) {
      updatedLots[i].lotIndex = i + 1;
    }

    setLots(updatedLots);
  };
  const deleteLotKeepStructure = (lotIndex) => {
    const updatedLots = [...lots];
    updatedLots.splice(lotIndex, 1);
    setLots(updatedLots);
  };
  const createNewEmptyLot = (position) => {
    // Create a new empty lot
    const newLot = {
      lotIndex: position + 1,
      imageUrl: [],
      analysis: null,
    };

    // Clone the lots array to avoid mutating the original state
    const updatedLots = [...lots];

    // Insert the new lot at the specified position
    updatedLots.splice(position, 0, newLot);

    // Update lotIndex for all subsequent lots to maintain proper order
    for (let i = position; i < updatedLots.length; i++) {
      updatedLots[i].lotIndex = i + 1;
    }

    // Update the state with the modified lots
    setLots(updatedLots);
    console.log("Updated lots after creating a new empty lot:", updatedLots);
  };
  if (checkDataFormat(lots)) {
    console.log("All lots are valid. Proceeding with transformation...");
    // Transform lots here
  } else {
    console.error("Some lots have invalid data. Fix them before proceeding.");
  }
  // Poll for status updates if in progress
  useEffect(() => {
    if (polling) {
      const interval = setInterval(() => {
        if (selectedUserId && selectedAnalysis) {
          fetchAnalysisDetails(selectedUserId, selectedAnalysis);
        }
      }, POLLING_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [polling, selectedUserId, selectedAnalysis]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="p-4 bg-white rounded shadow-md space-y-4">
      <Typography variant="h4" style={{ color: "#3f51b5", textAlign: "center" }}>
        View Image Analyses
      </Typography>
      <Button variant="contained" color="primary" onClick={handleTransformLots} disabled={loading}>
        Transform Lots
      </Button>

      {/* Button to Download CSV */}
      <Button variant="contained" color="secondary" onClick={handleDownloadCSV} disabled={!transformedLots.length}>
        Download CSV
      </Button>
      {/* Dropdown to Select User */}
      <FormControl fullWidth>
        <InputLabel id="select-user-label">Select User</InputLabel>
        <Select
          labelId="select-user-label"
          value={selectedUserId}
          onChange={handleUserChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name || user.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown to Select Analysis */}
      {selectedUserId && (
        <FormControl fullWidth>
          <InputLabel id="select-analysis-label">Select Image Analysis</InputLabel>
          <Select
            labelId="select-analysis-label"
            value={selectedAnalysis}
            onChange={handleAnalysisChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {imageAnalyses.map((analysis) => (
              <MenuItem key={analysis.podId} value={analysis.podId}>
                {analysis.podTitle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel id="custom-tone-label">Select Custom Tone</InputLabel>
        <Select
          labelId="custom-tone-label"
          value={selectedTone}
          onChange={(e) => {
            setSelectedTone(e.target.value);
            setPodDescription(customTones[e.target.value] || "");
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(customTones).map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Pod Description */}
      <Box mt={2}>
        <TextField
          fullWidth
          label="Pod Custom Description"
          value={podDescription}
          onChange={(e) => setPodDescription(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
        />
      </Box>

      {/* Checkbox to Remove Background for all lots */}
      <Box mt={2} display="flex" alignItems="center">
        <input
          type="checkbox"
          id="remove-bg-checkbox"
          checked={removeBg}
          onChange={(e) => setRemoveBg(e.target.checked)}
        />
        <label htmlFor="remove-bg-checkbox" className="ml-2">
          Remove Background for All Lots
        </label>
      </Box>

      {/* Actions for Pod */}
      <Box mt={4} display="flex" justifyContent="end" gap={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={updatePod}
          disabled={loading || analysisStatus === "in_progress" || analysisStatus == "completed"}
        >
          Update Pod
        </Button>
        <Button variant="contained" color="primary" onClick={analyzeImages} disabled={loading || analysisStatus === "in_progress" || analysisStatus == "completed"}>
          Analyze
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={() => setModalOpen(true)}
          disabled={loading || !lots.length}
        >
          Send to LN
        </Button>


      </Box>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Send to LN</DialogTitle>
        <DialogContent>
    
          <TextField
            fullWidth
            label="Subscription ID"
            value={subscriptionId}
            onChange={(e) => setSubscriptionId(e.target.value)}
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSendToLN} color="primary" disabled={loading}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
      {/* Display Status */}
      {analysisStatus === "not_started" && (
        <LotDisplay
          lots={lots}
          initialLotNumber={initialLotNumber}
          deleteLotRearrange={deleteLotRearrange}
          deleteLotKeepStructure={deleteLotKeepStructure}
          moveImageToLot={moveImageToLot}
          createNewEmptyLot={createNewEmptyLot}
        />
      )}

      {analysisStatus === "in_progress" && (
        <Box mt={4} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Typography variant="h6" className="ml-2">
            Analysis in Progress...
          </Typography>
        </Box>
      )}

      {analysisStatus === "completed" && (
        <Box className="mt-4">
          <Typography variant="h6" className="mb-4" style={{ fontWeight: "bold", textAlign: "center", color: "#3f51b5" }}>
            Analysis Results
          </Typography>
          {lotsWithEmptyTitles.length > 0 && (
            <Box mt={2} mb={2} display="flex" justifyContent="center">
              <div className=" text-center mr-2">
                <h3 className="text-lg font-semibold text-red-500">
                  Lots which failed: {lotsWithEmptyTitles.join(", ")}
                </h3>
              </div>
              <Button
                variant="contained"
                color="secondary"
                onClick={retryFailedLots}
                disabled={loading}
              >
                {loading ? "Retrying..." : `Retry Failed Lots (${lotsWithEmptyTitles.length})`}
              </Button>
            </Box>
          )}


          {lots.map((lot, index) => (
            lot.analysis ? (
              <Box key={index} className="p-4 border rounded mb-4 shadow-lg bg-gray-50">
                {/* Lot Index */}
                <Typography variant="h6" style={{ fontWeight: "bold", color: "#4caf50" }}>
                  Lot {lot.lotIndex}
                </Typography>

                {/* Analysis Details */}
                <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
                  <strong>Title:</strong> {lot.analysis.title}
                </Typography>
                <Typography variant="body1">
                  <strong>Description:</strong> {lot.analysis.description}
                </Typography>
                <Typography variant="body2" style={{ marginTop: "10px" }}>
                  <strong>Price:</strong> ${lot.analysis.price}
                </Typography>
                <Typography variant="body2">
                  <strong>Category:</strong> {lot.analysis.category}
                </Typography>
                <Typography variant="body2">
                  <strong>Brand:</strong> {lot.analysis.brand}
                </Typography>

                {/* Images Section */}
                <Box className="mt-4 flex flex-wrap gap-4">
                  {/* Original Image */}
                  {lot.original && lot.original.length > 0 && (
                    <Box>
                      <Typography variant="body2" style={{ marginBottom: "8px" }}>
                        <strong>Original Image:</strong>
                      </Typography>
                      <img
                        src={lot.original[0]}
                        alt={`Original for lot ${lot.lotIndex}`}
                        style={{
                          maxWidth: "200px",
                          maxHeight: "200px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </Box>
                  )}
                  {/* No Background Image */}
                  {lot.noBackground && lot.noBackground.length > 0 && (
                    <Box>
                      <Typography variant="body2" style={{ marginBottom: "8px" }}>
                        <strong>No Background Image:</strong>
                      </Typography>
                      <img
                        src={lot.noBackground[0]}
                        alt={`No background for lot ${lot.lotIndex}`}
                        style={{
                          maxWidth: "200px",
                          maxHeight: "200px",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Typography key={index} className="p-4 border rounded mb-4 bg-gray-100 text-gray-700">
                <strong>Lot {lot.lotIndex}:</strong> No analysis results available.
              </Typography>
            )
          ))}
        </Box>
      )}
    </div>
  );
};  

export default ImageUploader;